<template>
<div id="page" class="clinic-id-manage-index">
  <div class="cimi-statistical-part" v-loading="countLoading">
    <statistical :data="statisticalData" />
  </div>
  <div class="cimi-list-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="keyword">
          <el-input v-model="form.keyword" placeholder="姓名/联系方式/诊所名称" clearable>
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="代理地区" prop="txt_area_code">
          <el-cascader v-model="form.txt_area_code" clearable :options="regionOptions" :props="{checkStrictly: true, value: 'txt_code', label: 'txt_area_name', children: '_child'}" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-table :data="tableData" v-loading="tabelLoading">
      <el-table-column prop="txt_realname" label="姓名" align="center" />
      <el-table-column prop="txt_mobile" label="预留手机号" align="center" />
      <el-table-column prop="txt_area_name" label="代理地区" align="center">
        <template #default="scope">{{ scope.row.txt_area_pname }} / {{ scope.row.txt_area_name }}</template>
      </el-table-column>
      <el-table-column prop="txt_clinic_name" label="诊所名称" align="center" />
      <el-table-column prop="dt_create" label="创建时间" align="center" show-overflow-tooltip>
        <template #default="scope">{{scope.row.dt_create||"- -"}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="table-option">
        <template #default="scope">
          <div class="table-icon" @click="handleResetPsw(scope.row)">
            <el-tooltip class="box-item" effect="dark" content="重置密码" placement="top">
              <el-icon>
                <RefreshLeft />
              </el-icon>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  </div>
</div>
</template>

<script>
// 合伙人管理 - 诊所账号管理
import { reactive, toRefs, onMounted } from "vue";
import { Statistical, Pagination } from "components";
import { ElMessage, ElMessageBox } from 'element-plus';
import { RefreshLeft } from "@element-plus/icons-vue";
import { getClinicUserList, getStatCount, getAreas, resetPwd } from "api/apis.js";
export default {
  components: {
    Statistical,
    Pagination,
    RefreshLeft
  },
  setup() {
    const state = reactive({
      statisticalData: [
        { key: "allCount", end: "人", value: 0, zn: "注册总数", eg: "Number of registered" },
        { key: "todayCount", end: "人", value: 0, zn: "当日注册数量", eg: "Number of daily" }
      ],
      tableData: [],
      countLoading: false,
      total: 0,
      tabelLoading: false,
      form: {
        keyword: "",
        txt_area_code: "",
        page: 1,
        limit: 10,
      },
      regionOptions: [],
    })

    onMounted(() => {
      getAreaOption();
      getCount();
      getList();
    });

    // 重置密码
    const handleResetPsw = row => {
      ElMessageBox.confirm(
        `确定要重置用户【${row.txt_realname}】的密码吗？`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        state.tabelLoading = true;
        resetPwd({ t_clinic_config_id: row.t_clinic_config_id }).then(response => {
          if (response.code == 200) {
            ElMessage.success("重置成功");
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.tabelLoading = false;
        })
      })
    };

    // 获取地区信息
    const getAreaOption = () => {
      getAreas().then(response => {
        if (response.code == 200 && response.data) {
          state.regionOptions = response.data;
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取列表数据
    const getList = () => {
      // 请求参数赋值
      let param = {
        txt_search_key: (state.form.keyword || "").trim() == "" ? null : state.form.keyword,
        area_code: (state.form.txt_area_code || []).length > 0 ? state.form.txt_area_code[state.form.txt_area_code.length - 1] : null,
        page: state.form.page,
        limit: state.form.limit,
      }
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if (param[keys[i]] == null) {
          delete param[keys[i]];
        }
      }
      state.tabelLoading = true;
      getClinicUserList(param).then(response => {
        if (response.code == 200 && response.data) {
          state.tableData = response.data.data;
          state.total = response.data.count;
        } else {
          state.tableData = [];
          state.total = 0;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };

    // 统计
    const getCount = () => {
      state.countLoading = true;
      getStatCount().then(response => {
        if (response.code == 200) {
          let keys = Object.keys(response.data);
          for (let i = 0; i < keys.length; i++) {
            let index = state.statisticalData.findIndex(t => t.key == keys[i]);
            if (index != -1) {
              state.statisticalData[index].value = response.data[keys[i]];
            }
          }
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.countLoading = false;
      })
    };

    return {
      ...toRefs(state),
      getList,
      getCount,
      getAreaOption,
      handleResetPsw
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-id-manage-index {
  .cimi-list-part {
    margin-top: 24px;
    height: calc(100% - 150px);

    .el-table {
      height: calc(100% - 100px);
    }
  }
}
</style>
